:root {
    --mythic: #3bdbdd;
    --ultra: #fb2576;
    --super: #41ffa5;
}

.myTable {
    border-collapse: collapse;
    border: 1px solid rgba(0, 0, 0, 0);
}

.mythic {
    background: var(--mythic);
}

.ultra {
    background: var(--ultra);
}

.super {
    background: var(--super);
}

.petal input[type=number] {
    display: inline-block;
    margin: 0 2px;
    /* border: none; */
    border: 1px solid black;
    width: 50px;
    outline: none;
    appearance: textfield;
    -moz-appearance: textfield;
}

.petal input[type=number]::placeholder {
    color: #ffffff;
    font-style: italic;
}

.petal input::-webkit-outer-spin-button,
.petal input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}