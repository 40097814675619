.correct {
    color: green;
}
.incorrect {
    color: red;
}

.link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: blue;
    font-family: Tahoma, Arial, sans-serif;
    text-decoration: underline;
    cursor: pointer;
}

.game {
    padding-top: 10px;
    font-size: 2em;
    word-wrap: break-word;
    font-family: "Lucida Console", "Courier New", monospace;
}

.header {
    font-size: 1.5em;
    font-family: Tahoma, Arial, sans-serif;
    position: sticky;
    border-bottom: 2px dashed black;
}