ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #181818;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

li {
    float: left;
}

li a {
    display: block;
    color: white;
    text-align: center;
    padding: 3px 16px;
    text-decoration: none;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
    cursor: pointer;
    background-color: #111;
}

.active {
    background: #0050bf;
    pointer-events: none;
    cursor: default;
}