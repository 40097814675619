#container {
    padding: 10px;
}

#game {
    width: 500px;
    height: 300px;
    background: #353535;
}

#piece {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: red;
    position: relative;
}