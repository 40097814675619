#animationContainer, #mouseTracker {
    width: 400px;
    height: 200px;
}

#animationContainer {
    position: relative;
    background: cyan;
    z-index: 2;
    margin-left: 15px;
}

#mouseTracker {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
}

.brick {
    position: absolute;
    width: 50px;
    height: 25px;
    background-color: blue;
    border: 1px solid black;
}

.animate {
    position: absolute;
    z-index: 4;
}

.paddle {
    height: 2px;
    position: absolute;
    background: black;
    top: 150px;
}

.animationText {
    text-align: right;
    font-size: 0.7em;
    z-index: 3;
}

.debug {
    text-align: left;
    padding: 10px;
}

.lose {
    padding: 10px;
}

.score {
    padding: 10px;
}

.aDebug {
    border: 1px solid transparent;
}

.aNoDebug {
    border: 1px solid black;
}