#captchaContainer {
    width: 300px;
    height: 75px;
    border: 1px solid black;

    display: inline-flex;
    flex-direction: row;
    align-items: center;
}

#captchaContainer input[type=checkbox] {
    display: inline;
    width: 50px;
    height: 50px;
}

#captchaContainer label {
    padding: 2px;
}

.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modalContent {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
}